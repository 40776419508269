

// import './App.css';
// import localTravelImage from './assets/feature1.webp';
// import optimalRouteImage from './assets/feature2.webp';
// import emergencyResponseImage from './assets/feature3.webp';
// import HeaderBar from './HeaderBar';
// import MainHeader from './MainHeader'
// import TextBubbleList from './TextBubbleList';
// import GradientButton from './GradientButton';
// import FeatureItem from './FeatureItem';
// import MainFooter from './MainFooter';
// import HighlightText from './HighlightText'; // 컴포넌트를 임포트합니다.

// function App() {
//   const handleClick = () => {
//     console.log('Button was clicked!');
//   };

//   // Ref to store multiple element references
//   const elementsRef = useRef([]);

//   useEffect(() => {
//     // Intersection Observer configuration
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add('fade-in');
//             observer.unobserve(entry.target); // Remove observer once the element is visible
//           }
//         });
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is visible
//     );

//     elementsRef.current.forEach((el) => {
//       if (el) observer.observe(el);
//     });

//     return () => {
//       if (elementsRef.current) {
//         elementsRef.current.forEach((el) => {
//           if (el) observer.unobserve(el);
//         });
//       }
//     };
//   }, []);

//   return (
//     <div className="App">
//       <div className='App-header'>
//         <HeaderBar />
//       </div>
//       <p ref={(el) => (elementsRef.current[0] = el)} className="animate"/>
//       <div ref={(el) => (elementsRef.current[1] = el)} className="animate">
//         <MainHeader />
//       </div>
//       <div
//         ref={(el) => (elementsRef.current[2] = el)}
//         className="animate"
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '60px',
//           marginTop: '20px',
//         }}
//       >
//         <GradientButton text="Download App" type="solid" onClick={handleClick} />
//       </div>
//       <div
//         ref={(el) => (elementsRef.current[3] = el)}
//         className="animate"
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '60px',
//         }}
//       >
//         <GradientButton text="Try now" onClick={handleClick} />
//       </div>
//       <p className='spaced'/>
//       <div ref={(el) => (elementsRef.current[4] = el)} className="animate">
//         <TextBubbleList />
//       </div>
//       <p className='spaced'/>
//       <hr className="hrStyle" />
//       <p className='spaced' />
//       <div ref={(el) => (elementsRef.current[5] = el)} className="animate">
//         <HighlightText 
//               fullText="왜 포켓 가이드<br/>일까요?" 
//               highlights={["24시간", "포켓 가이드"]}
//               fontSizeType='small'
//             />
//       </div>
//       <div ref={(el) => (elementsRef.current[6] = el)} className="animate">
//           <FeatureItem
//             title="진정한 로컬 여행의 시작"
//             image={localTravelImage}
//             description="GPS 기반 가이드를 통해 각 목적지의 핵심을 경험하세요. 실시간으로 제공되는 로컬 정보와 숨겨진 명소들을 통해 마치 현지인처럼 여행을 즐길 수 있습니다. 여러분의 관심사와 위치에 맞춰 맞춤 여행을 제공합니다."
//             buttonTitle="진정한 로컬 여행 체험하기"
//           />
//       </div>
//       {/* <p className='spaced' /> */}
//       <div ref={(el) => (elementsRef.current[7] = el)} className="animate">
//           <FeatureItem
//             title="원활한 루트 최적화"
//             image={optimalRouteImage}
//             description="AI 기술을 이용한 원터치 여행 계획으로 최적의 경로를 찾아 효율적으로 탐험하세요. 이동 시간을 줄이고 체험 시간을 늘려 여행의 즐거움을 극대화합니다."
//             buttonTitle="루트 최적화 체험하기"
//           />
//       </div>
//       {/* <p className='spaced' /> */}
//       <div ref={(el) => (elementsRef.current[8] = el)} className="animate">
//           <FeatureItem
//             title="실시간 위기 대응"
//             image={emergencyResponseImage}
//             description="현지 뉴스 소스로부터 즉각적인 업데이트를 제공받아 안전하고 정보에 입각한 여행을 할 수 있습니다. 필수 실시간 알림과 정보를 통해 어떤 상황에서도 자신감 있게 대처하세요."
//             buttonTitle="현지 위기 정보 확인하기"
//           />
//       </div>
//       <p className='spaced' />
//       <MainFooter/> 
//       <p className='spaced' />
//       <footer className="App-footer">
//         <p>© 2024 AI Guide, Inc.</p>
//       </footer>
//     </div>
//   );
// }

// export default App;
// App,js

import React, { useEffect, useRef } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

// import HomePage from './pages/home/HomePage';
// import QuoteDetail from './pages/QuoteDetail';
// import NewQuote from './pages/NewQuote';
// import NotFound from './pages/NotFound';

const HomePage = React.lazy(() => import('./pages/home/HomePage'));

const App = () => {
    return (
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {/* <Route path="/quotes" element={<AllQuotes />} />
              <Route path="/quotes/:quoteId" element={<QuoteDetail />} />
              <Route path="/new-quote" element={<NewQuote />} />
              <Route path="/*" element={<NotFound />} /> */}
          </Routes>
          </div>
        </Router>
    );
};

export default App;
